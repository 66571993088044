<template>
    <div class="viewBox">
      <!-- top -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>专家管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="goback1">申请列表</el-breadcrumb-item>
            <el-breadcrumb-item>申请详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="top">
            <BlueBox :msg="msg1"/>
            <div class="topRight">
                <div>
                    <span class="spant2" v-if="pageData.status=='申请中'">{{pageData.status}}</span>
                     <span class="spant3" v-if="pageData.status=='已通过'">{{pageData.status}}</span> 
                    <span class="spant4" v-if="((pageData.status!='申请中')&&(pageData.status!='已通过'))">{{pageData.status}}</span>
                </div>
            </div>
        </div> 
        <div class="baseBox">
            <div class="title">
                <div class="circle"></div>
                <span class="baseTitle">基本信息</span>
            </div>
            <div class="dataBox" v-if="pageData">
                <div class="info">
                    <div class="infoLine"><span class="fromT">姓名</span><span class="backT">{{pageData.name}}</span></div>
                    <div class="infoLine"><span class="fromT">申请时间</span><span class="backT">{{pageData.applyDate}}</span></div>
                    <div class="infoLine"><span class="fromT">所属机构</span><span class="backT">{{orgName}}</span></div>
                </div>
                <!-- center -->
                <div class="info">
                    <div class="infoLine"><span class="fromT" >性别</span><span class="backT" >{{pageData.gender}}</span></div>
                     <!-- <div class="infoLine"><span class="fromT">证件类型</span><span class="backT">{{pageData.name}}</span></div>  -->
                    <div class="infoLine"><span class="fromT">所属科室</span><span class="backT">{{deptName}}</span></div>
                </div>
                <!-- right -->
                <div class="info" style="margin-right:200px">
                    <div class="infoLine"><span class="fromT">手机号码</span><span class="backT">{{pageData.mobile}}</span></div>
                    <div class="infoLine"><span class="fromT">证件号码</span><span class="backT">{{pageData.cardId}}</span></div>
                    <div class="infoLine"><span class="fromT">职位</span><span class="backT">{{pageData.jobTitle}}</span></div>
                </div>
            </div>
        </div>
        <!-- best -->
        <div class="bestBox" v-if="pageData.status!='已通过'">
            <div class="rightBotton">
                <div class="refuse" @click="refuse">拒绝</div>
                <div class="permit" @click="permit">通过</div>
            </div>
        </div>
            <!-- 编辑弹窗 -->
            <el-dialog title="修改机构信息" width="420px" :visible.sync="editOrgDetail">
                <el-form :model="orgUpdateForm" ref="orgUpdateForm">
                    <el-form-item label="机构名称" prop="orgName" style="margin-left:20px">
                        <el-select v-model="orgUpdateForm.orgName" filterable clearable placeholder="请选择" @focus="slectOrg" @change="getOrg">
                        <el-option
                            v-for="(item,index) in orgList"
                            :key="index"
                            :label="item.orgName"
                            :value="item.id">
                        </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="科室名称" prop="depeName" style="margin-left:20px">
                        <el-select v-model="orgUpdateForm.depeName" filterable clearable placeholder="请选择" @change="setDept">
                        <el-option
                            v-for="(item,index) in deptList"
                            :key="index"
                            :label="item.deptName"
                            :value="item.id">
                        </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <!-- footer -->
                <div class="footerSub">
                    <div class="tijiao" @click="cancelP">取消</div>
                    <div class="tijiao" @click="sureP">保存</div>
                </div>
        </el-dialog>
        <!-- 机构不合法弹窗 -->
        <el-dialog
            title="提示"
            :visible.sync="showOrg"
            width="30%"
            center>
            <span>当前机构不合法，请重新修改,否则无法通过申请</span>
            <span slot="footer" class="dialog-footer" style="display:flex; align-items: center;justify-content: center">
                <div class="foor-cancel" @click="cencelS">取消</div>
                <div class="foor-sure" @click="sureS">确认</div>
            </span>
        </el-dialog>
        
    </div>
  </template>
  <script>
  import BlueBox from '@/components/other/blueBox.vue'
  import {getOrgList} from '@/api/orgController.js'
  import {getRoomList} from '@/api/order.js'
  import {viewDotor,viewOrgList,allow,disAllow} from '@/api/expert.js'
  export default {
    beforeRouteLeave(to,from,next){
      if(to.name === 'ApplyList'){
        to.meta.keepAlive = true;
      }
      next();
    },
    components:{
        BlueBox
    },
      data(){
        return{
            msg1:'申请详情',
            editOrgDetail:false,
            dataId:null,
            orgId:null,
            deptId:null,
            orgList:null,
            deptList:null,
            status:null,
            orgName:null,
            deptName:null,
            pageData:null,
            orgUpdateForm:{
                orgName:null,
                depeName:null,
            },
            showOrg:false,//机构弹窗
        }
      },
      created(){
        if(this.$route.query.id){
            this.dataId = this.$route.query.id;
            this.status = this.$route.query.status;
            this.view(this.dataId);
        }
      },
      methods:{
        //面包屑
        goback1(){
            this.$router.go(-1);
        },
        refuse(){
            disAllow({
                id:this.dataId,
            }).then(res=>{
                if(res.data.status == 200){
                    this.$message({
                    message: '已拒绝！',
                    type: 'success'
                    });
                    this.$router.go(-1)  
                }else{
                    this.$message.error(res.data.message);
                }
            }).catch(res=>{
                this.$message.error(res);
            })
            
        },
        permit(){
            allow({
                deptId:this.deptId,
                id:this.dataId,
                orgId: this.orgId
            }).then(res=>{
                if(res.data.status == 200){
                    this.$message({
                    message: '通过成功！',
                    type: 'success'
                    });
                    this.$router.go(-1)  
                }else{
                    this.$message.error(res.data.message);
                }
            }).catch(res=>{
                this.$message.error(res);
            })
        },
        cencelS(){
            this.$router.go(-1)  
        },
        sureS(){
            this.showOrg = false;
            this.editOrgDetail = true;
        },
        //查看医生
        view(id){
            viewDotor({id:id}).then(res=>{
            if(res.data.status == 200){
                this.pageData = res.data.data;
                this.orgName = res.data.data.orgName;
                this.deptName  = res.data.data.deptName;
                //开始判断
                if(this.status!='已通过'){
                    this.showOrg = true;
                }else{
                    this.showOrg = false;
                }
            }else{
                this.$message.error(res.data.message);
            }
          }).catch(res=>{
             this.$message.error(res);
          })
        },
        //获取机构
        slectOrg(){
          getOrgList({}).then(res=>{
            if(res.data.status == 200){
              const orgListF =res.data.data.records;
              this.orgList = orgListF;
            }else{
                this.$message.error(res.data.message);
            }
          }).catch(res=>{
             this.$message.error(res);
          })
        },
        //获取科室
        getDept(orgId){
            getRoomList({orgId:orgId}).then(res=>{
                if(res.data.status == 200){
                const deptListF =res.data.data.records;
                this.deptList = deptListF;
                }else{
                    this.$message.error(res.data.message);
                }
            }).catch(res=>{
                this.$message.error(res);
            })
        },
        getOrg(e){
            this.orgId = e;
            this.getDept(this.orgId);
        },
        setDept(e){
            this.deptId = e;
        },
        //取消设置
        cancelP(){
            this.editOrgDetail = false;
        },
        sureP(){
            this.editOrgDetail = false;
            //赋值 name
            this.orgName = this.orgUpdateForm.orgName;
            this.deptName = this.orgUpdateForm.deptName;
        }
      }
  }
  </script>
  <style scoped lang="stylus">
  //change elemen-ui style
  /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
  @import '~@/assets/css/newTask.css'
  .foor-cancel
    width: .94rem
    height: .34rem
    background-color: #909399
    line-height: .34rem
    text-align: center
    cursor pointer
    border-radius: .05rem
    color:#fff
    margin-right: .31rem
  .foor-sure
    width: .94rem
    height: .34rem
    background-color: #00CCCC;
    line-height: .34rem
    text-align: center
    cursor pointer
    border-radius: .05rem
    color:#fff
   
.viewBox
    overflow hidden
    .top
        margin-top: 36px 
        display: flex
        justify-content: space-between
    .topRight
        display: flex
        align-items: center
        .spant2
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            margin-left: 16px
            line-height: 22px;
        .spant3
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            margin-left: 16px
            line-height: 22px;
        .spant4
            font-size: 16px;
            font-weight: 400;
            color: #FC5555;
            margin-left: 16px
            line-height: 22px;
    .baseBox
        width: 100%
        height: 252px 
        margin-top: 20px
        border-radius: 8px 
        background-color: #F4FBFE
        box-sizing: border-box
        padding: 30px 32px  
        .title
            display: flex
            align-items: center
            .circle
                width: 10px
                height: 10px
                border-radius: 50%
                margin-left: -16px
                background-color: #00cccc
            .baseTitle
                font-size: 16px;
                font-weight:bold;
                color: #323233;
                line-height: 22px;
                margin-left: 6px
        .dataBox
            margin-top: 36px
            width: 100%
            height: 140px
            display: flex
            justify-content: space-between
            .info
                display: flex
                flex-direction: column
                justify-content: space-between
                .infoLine
                    display: flex
                    align-items: center
                    .fromT 
                        color: #353535;
                        font-size: 16px
                        line-height: 20px;
                    .backT
                        color: #858585;
                        line-height: 20px;
                        margin-left: 20px;
                        font-size:14px;
    .bestBox
        width: 100%
        margin-top: 40px  
        display: flex
        justify-content: flex-end
        .rightBotton
            width: 344px 
            display: flex
            justify-content: space-between
            .refuse
                width: 160px;
                height: 44px;
                background: #FFFFFF;
                text-align: center
                line-height: 44px 
                border-radius: 6px;
                border: 1px solid #FC5555;
                font-size: 16px;
                font-weight: 500;
                color: #FC5555;
                cursor pointer
            .permit
                width: 160px;
                height: 44px;
                text-align: center
                line-height: 44px 
                background: #00CCCC;
                border-radius: 6px;
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                cursor pointer
.footerSub
    width: 300px
    margin: 0 auto
    display: flex
    justify-content: space-evenly
    .tijiao
        width:80px
        height:32px
        font-size:16px
        color:#fff;cursor:pointer
        background-color: #00cccc
        line-height: 32px
        border-radius:4px
        text-align: center
                
  </style>