<template>
    <div class="blue">
        <div class="blueLeft"></div>
        <div class="blueData">{{msg}}</div>
    </div>
</template>
<script>
export default {
    props: {
    msg: String
  }
}
</script>

<style scoped lang="stylus">
    .blue
        display: flex
        align-items: center
        .blueLeft
            width: 3px;
            height: 22px;
            background: #00CCCC;
            border-radius: 3px;
        .blueData
            font-size: 16px;
            margin-left: 6px
            color: #353535;
            line-height: 25px;
</style>