//专家模块
import  request  from "@/utils/request";

//专家申请列表
    export function applyList (parameter){
        return request({
        url:'/api/hospital-doctor/getApplyDoctorList',
        method:'post',
        data:parameter
    })
    }

//查看医生详情1
export function viewDotor (parameter){
    return request({
    url:'/api/hospital-doctor/viewApplyDoctor',
    method:'post',
    data:parameter
})
}

//查看医生详情2
export function viewDotor2 (parameter){
    return request({
    url:'/api/hospital-doctor/viewHospitalDoctor',
    method:'post',
    data:parameter
})
}

//查看机构列表
export function viewOrgList (parameter){
    return request({
    url:'/api/org/list',
    method:'post',
    data:parameter
})
}

//通过申请
export function allow (parameter){
    return request({
    url:'/api/hospital-doctor/passDoctorApply',
    method:'post',
    data:parameter
})
}

//不通过申请
export function disAllow (parameter){
    return request({
    url:'/api/hospital-doctor/rejectDoctorApply',
    method:'post',
    data:parameter
})
}

//专家列表
export function doctorList (parameter){
    return request({
    url:'/api/hospital-doctor/getHospitalDoctorList',
    method:'post',
    data:parameter
})
}

//禁用
export function closeDoctor (parameter){
    return request({
    url:'/api/hospital-doctor/updateHospitalDoctor',
    method:'post',
    data:parameter
})
}

//更新医生状态
export function updateStatus (parameter){
    return request({
    url:'/api/hospital-doctor/updateDoctorStatus',
    method:'post',
    data:parameter
})
}