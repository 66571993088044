//预约模块
import  request  from "@/utils/request";

//排班管理
    //排班管理--排班情况
        //获取科室列表
        export function getRoomList (parameter){
            return request({
            url:'/api/dept/getDeptList',
            method:'post',
            data:parameter
        })
        }

        //获取排班详情列表
        export function getSchduList (parameter){
            return request({
            url:'/api/workday/getWorkdayList',
            method:'post',
            data:parameter
        })
        }

         //修改最大号源
         export function changeMaxNum (parameter){
            return request({
            url:'/api/workday/updateWorkday',
            method:'post',
            data:parameter
        })
        }
    
    //排班管理--排班规则
        //查询排班规则列表
        export function getRulesList (parameter){
            return request({
            url:'/api/workday-rule/getWorkdayRuleList',
            method:'post',
            data:parameter
        })
        }

        //设置规则生效时间
        export function activeTime (parameter){
            return request({
            url:'/api/workday/createWorkday',
            method:'post',
            data:parameter
        })
        }

        //查看规则
        export function viewRules (parameter){
            return request({
            url:'/api/workday-rule/viewWorkdayRule',
            method:'post',
            data:parameter
        })
        }


        //新建规则
        export function addRules (parameter){
            return request({
            url:'/api/workday-rule/insertWorkdayRule',
            method:'post',
            data:parameter
        })
        }

        //更新规则
        export function updateRules (parameter){
            return request({
            url:'/api/workday-rule/updateWorkdayRule',
            method:'post',
            data:parameter
        })
        }

        //查询医院列表
        export function getHospitalList (parameter){
            return request({
            url:'/api/org/viewCurrentOrg',
            method:'post',
            data:parameter
        })
        }





//预约管理
    //预约康复列表
    export function getRecoverList (parameter){
        return request({
        url:'/api/workday/getPatientOrderList',
        method:'post',
        data:parameter
    })
    }

    //预约康复列表--查看预约详情
    export function viewRecover (parameter){
        return request({
        url:'/api/hospital/patient/viewPatient',
        method:'post',
        data:parameter
    })
    }

    //预约管理--完成预约
    export function completeOrder (parameter){
        return request({
        url:'/api/workday/finishPatientOrder',
        method:'post',
        data:parameter
    })
    }




//机构信息
    //获取科室列表 
    export function getkeshiList (parameter){
        return request({
        url:'/api/dept/getDeptList',
        method:'post',
        data:parameter
    })
    }

    //新增科室  
    export function addDept (parameter){
        return request({
        url:'/api/dept/insertDept',
        method:'post',
        data:parameter
    })
    }
    //查看科室  
    export function viewDept (parameter){
        return request({
        url:'/api/dept/viewDept',
        method:'post',
        data:parameter
    })
    }

    //更新科室  
    export function updateDept (parameter){
        return request({
        url:'/api/dept/updateDept',
        method:'post',
        data:parameter
    })
    }


    //获取上传签名
    export function getSignature(parameter) {
        return request({
        url: '/api/wx/home-page/getImageOssSign',
        method: 'post',
        data: parameter,
        });
    }

    //上传img
    export function updateOrg(parameter) {
        return request({
        url: '/api/org/update',
        method: 'post',
        data: parameter,
        });
    }
    //上传logo
    export function uploadLogo (parameter,onUploadProgress){
        return request({
        headers:{'Content-Type': 'multipart/form-data'},
        onUploadProgress,
        url:'',
        method:'post',
        data:parameter
        
    })
    }

    //获取科室列表
    export function getDeptList(parameter) {
        return request({
        url: '/api/dept/getDeptList',
        method: 'post',
        data: parameter,
        });
    }